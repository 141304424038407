import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import NavLeft from "../NavLeft/NavLeft";
import CardItem from "./CardItem/CardItem";

import {useEffect, useState,useRef} from "react";

import "./Main.css";
import axios from "axios";
import {useMediaQuery} from "@mui/material";
import theme from "../../store/theme";
import FormDialog from "../FormDialog/FormDialog";
import Link from '@mui/material/Link';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import {useMyContext} from "../../store/stateContext";

import { useRoutes,useLocation,useParams } from "react-router-dom";
import routes from "../../routes";

export default function Main(){

    const element = useRoutes(routes);

    const [view, setView] = useState('list');

    const [recommend,setRecommend] = useState('');

    const [catInfo,setCatInfo] = useState('');

    // const [isSearch,setSearch] = useState(false);
    const store = useMyContext();
    // const [isSearch,setSearch] = useMyContext();
    // console.log(store)

    const [searchInfo,setSearchInfo] = useState('');

    const searchRef = useRef();

    const navRef = useRef();

    const handleChange = (event, nextView) => {
        setView(nextView);
    };

    // const handleAlignment = (event, newAlignment) => {
    //     if (newAlignment !== null) {
    //         setAlignment(newAlignment);
    //     }
    // };

    const {id} = useParams();   //当前分类的id
    console.log(id);
    let navData;


    let isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    let itemClass2 = '';
    let [itemClass,setItemClassVal] = useState('');


    let isUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    let isUpMd = useMediaQuery(theme.breakpoints.up('md'));
    let isUpLg = useMediaQuery(theme.breakpoints.up('lg'));


    const getCardClass = () => {
        if (isUpLg) return 'c-loop-lg';
        if (isUpMd) return 'c-loop-md';
        if (isUpSm) return 'c-loop-sm';
        return 'c-loop-xs';
    };


    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));



    useEffect(() => {

        axios.post("https://nav.bcdesign.cn/index.php/getLinks",{id},{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(function (response) {


            navData = response.data;

            // console.log(navData);return;


            let res = navData.recommends.map(item=>(
                <Link sx={{
                '&:hover': {
                    background: 'rgba(255, 255, 255, 0)',
                }
            }} href={item.link_url} target={'_blank'} key={item.id} underline="none">
                <CardItem key={item.id} id={item.id} keywords={item.keywords} description={item.description} link_url={item.link_url} logo_pic={item.logo_pic} pid={item.pid} recommmend={item.recommended} title={item.title} />
            </Link>
                ));

            setRecommend(res);

            let info = navData.cat.map(item=>(

                    <Box key={item.id} id={item.anchor} className={"part-cat-block"}>
                        <h2 className={"c-title"}>
                            <a name={"heightPic"}></a>
                            <strong>{item.title}</strong>
                        </h2>
                        <div className="c-content">
                            <div className={getCardClass()}>

                                {item.data.map(item2=>(

                                    <Link sx={{
                                    '&:hover': {
                                        background: 'rgba(255, 255, 255, 0)',
                                    }
                                }} href={item2.link_url} target={'_blank'} underline="none" key={item2.id}>
                                        <CardItem key={item2.id} id={item2.id} keywords={item2.keywords} description={item2.description} link_url={item2.link_url} logo_pic={item2.logo_pic} pid={item2.pid} recommmend={item2.recommended} title={item2.title} />
                                    </Link>

                                ))}


                            </div>
                        </div>
                    </Box>

            ))

            setCatInfo(info);

        }).catch(function (error) {

            console.log(error);
        })


    }, [isUpLg, isUpMd, isUpSm,id]);




    const findLinks = () => {

        axios.post("https://nav.bcdesign.cn/index.php/findLinks",{

            'keyword':searchRef.current.value

        },{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(function (response) {


            let navData2 = response.data;

            let info = navData2.map(item3=>(

                    <Link sx={{
                        '&:hover': {
                            background: 'rgba(255, 255, 255, 0)',
                        }
                    }} href={item3.link_url} target={'_blank'} underline="none" key={item3.id}>
                        <CardItem key={item3.id} id={item3.id} keywords={item3.keywords} description={item3.description} link_url={item3.link_url} logo_pic={item3.logo_pic} pid={item3.pid} recommmend={item3.recommended} title={item3.title} />
                    </Link>

            ));


            if(navData2.length!=0){
                setSearchInfo(info);
                store.setSearch(true);
            }else{
                store.setSearch(false);
            }

        }).catch(function (error) {

            console.log(error);
        })

    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            findLinks();
        }
    };

    let [isMin,setMin] = useState(false);
    // function checkMinWidth() {
    //     const divNav = document.getElementById('navleft');
    //     const computedStyle = window.getComputedStyle(divNav);
    //     const currentWidth = divNav.clientWidth;
    //     const minWidth = parseFloat(computedStyle.minWidth);
    //
    //     if (currentWidth >= minWidth) {
    //         console.log(`The div's width (${currentWidth}px) has reached or exceeded the min-width (${minWidth}px).`);
    //     } else {
    //         console.log(`The div's width (${currentWidth}px) is less than the min-width (${minWidth}px).`);
    //     }
    // }

    // function checkMinWidth() {
    //     const divNav = navRef.current;
    //     let minWidth,currentWidth;
    //
    //     if(navRef.current !== undefined && divNav !== null){
    //         minWidth = parseFloat(window.getComputedStyle(navRef.current).minWidth);
    //         // console.log(navRef.current.clientWidth);
    //         currentWidth = divNav.clientWidth;
    //         if (currentWidth >= minWidth) {
    //             setMinW('16vw');
    //         } else {
    //             setMinW('175px');
    //         }
    //     }
    //
    //     console.log(minW);
    // }
    // //
    // window.addEventListener('resize', checkMinWidth);
    // window.addEventListener('load', checkMinWidth);

    // console.log(window.getComputedStyle(navRef.current));

    // if(navRef.current !== undefined){
    //     // console.log(window.getComputedStyle(navRef.current).minWidth);
    //     console.log(navRef.current.clientWidth);
    //
    // }
    window.addEventListener('resize', ()=>{
        if(navRef.current !== undefined  && navRef.current !== null){
                    // console.log(navRef.current.clientWidth);
                    let currentWidth = navRef.current.clientWidth;
                    if (currentWidth >= 174) {
                        setMin(false);
                    } else {
                        setMin(true);
                    }
        }
    });




    return <Box>
        <Box className={"container"} sx={{
            marginLeft:isDownSm ? '0px' : isMin ? '170px' : '16vw'
        }}>

            <Box className={"main"} sx={{
                margin:'10px'
            }}>


                    <Box className={"part-search"}>


                        <Box className="search-main">

                            {
                                isUpSm ?
                                    <h2 className="search-title">3d打印企业信息导航</h2>
                                   :
                                    <h2 className="search-title-small">3d打印企业信息导航</h2>

                            }


                            <Box className="search-form">
                                <TextField
                                    label={
                                        <Typography color="#FFFFFF" variant="button">
                                            3d打印
                                        </Typography>
                                    }
                                    onKeyPress={handleKeyPress}
                                    InputProps={{
                                        inputMode: 'email'
                                    }}
                                    sx={{
                                        width:isUpLg ? '40%' : (isUpMd ? '50%' :(isUpSm ? '60%':'70%')),
                                        boxSizing:'border-box',

                                        '& .MuiOutlinedInput-root': {
                                            background: 'rgba(255, 255, 255, 0.2)',
                                            webkitBackdropFilter: ' blur(10px)',
                                            backdropFilter: 'blur(10px)',
                                            border: 'none',
                                            borderRadius:'0.4em 0 0 0.4em',
                                            transition: 'background-color 0.3s ease',
                                            '&:hover': {
                                                background: 'rgba(255, 255, 255, 0.3)',
                                            },
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                    }}
                                    inputRef={searchRef}
                                >
                                </TextField>
                                <Button
                                    sx={{
                                        width:'10%',
                                        background: 'rgba(255, 255, 255,1.0)',
                                        webkitBackdropFilter: ' blur(10px)',
                                        backdropFilter: 'blur(10px)',
                                        '&:hover': {
                                            background: 'rgba(255,255,255,1.0)', // 鼠标移入时的背景颜色
                                        },
                                        height:'56px'
                                    }}
                                    variant="contained"

                                    onClick={()=>{
                                        // console.log(searchRef.current.value);
                                        findLinks();
                                    }}
                                >
                                    <Typography color="#000000" variant="button">搜索</Typography>
                                </Button>
                            </Box>

                            <Box className="search-keywords">

                            </Box>

                        </Box>


                    </Box>

                    <FormDialog></FormDialog>


                    {
                        !store.isSearch &&
                    <Box className={"part-cats-loop"}>

                        <Box className={"part-cat-block"}>
                            <h2 className={"c-title"}>
                                <a name={"hot"}></a>
                                <strong>热门推荐</strong>
                            </h2>
                            <div className="c-content">
                                <div className={isUpLg ? 'c-loop-lg' : (isUpMd ? 'c-loop-md' :(isUpSm ? 'c-loop-sm':'c-loop-xs'))}>

                                    {recommend}

                                </div>
                            </div>
                        </Box>

                        {catInfo}

                    </Box>
                    }

                    <Box className={"part-cats-loop"}>
                        <div className="c-content">
                            <div className={isUpLg ? 'c-loop-lg' : (isUpMd ? 'c-loop-md' :(isUpSm ? 'c-loop-sm':'c-loop-xs'))}>
                        {
                            store.isSearch && searchInfo
                        }

                            </div>
                        </div>
                    </Box>


            </Box>

            {
                isDownSm ? '' : (
                    <Box sx={{
                        left:0,
                        width:'15vw',
                        height:'100vh',
                        position:'fixed',
                        zIndex:2,
                        top:'56px',
                        backgroundColor:'white',
                        minWidth:'170px'
                    }}
                        ref={navRef}
                    >

                        <NavLeft />

                    </Box>
                )
            }


        </Box>

    </Box>;
}
