import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import classes from "./Favorite.module.css";

export default function Favorite(){
    return <Box sx={{
        backgroundColor:'#262626',
        height:'80px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        position:'relative',
        zIndex:3
    }}>

        <Typography sx={{
            color:'white',
        }}>
            按 <span className={classes.ctlButton}>Command</span> + <span className={classes.ctlButton}>D</span> 一键收藏！
        </Typography>

    </Box>
}
