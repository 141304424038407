import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import theme from "../../../store/theme";
import {useMediaQuery} from "@mui/material";

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export default function CardItem(props)
{

    // let keywords = props.keywords.split(',');

    let [keywordHTML,setKeyword] = useState();

    // let [itemwidth,setItemwidth] = useState('20%');
    let itemClass = '';

    let isUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    let isUpMd = useMediaQuery(theme.breakpoints.up('md'));
    let isUpLg = useMediaQuery(theme.breakpoints.up('lg'));


    if(isUpLg){
        itemClass = 'c-loop-lg';
    }else{
        itemClass = 'c-loop-md';

        if(isUpMd){
            itemClass = 'c-loop-md';
        }else{
            itemClass = 'c-loop-sm';

            if(isUpSm){
                itemClass = 'c-loop-sm';
            }else{
                itemClass = 'c-loop-xs';
            }
        }

    }

    function replaceChar(str, oldChar, newChar) {
        var regex = new RegExp(oldChar, 'g');
        return str.replace(regex, newChar);
    }

    useEffect(() => {

        // let keywords = props.keywords != '' && props.keywords.split('，');

        let keywords= null;

        if(props.keywords != ''){
            keywords = props.keywords.split(' ');
        }

        // console.log(keywords.split('，'));

        let res = keywords.map((item,index)=><Button sx={{color:'#525252'}} key={index} size="small">{item}</Button>)

        setKeyword(res);
    }, []);


    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            // maxWidth: 180, // 设置最大宽度
            // minWidth: 180, // 设置最大宽度
            width:180,
            textAlign:'center'
        },
    }));

    return (
        <Box className={'p-item'}
            sx={{
                width:'100%'
            }}
        >

            <Card sx={{
                maxWidth: 345 ,
                textAlign:'center',
                paddingTop:'20px',
                borderRadius:3,
                height:220,
                cursor:'pointer',
                '&:hover': {
                    position: "relative",
                    top:'-2px',
                    // boxShadow:'2px 2px 10px #aaaaaa'
                },
            }}>
                <Box sx={{
                    display:'flex',
                    justifyContent:'center'
                }}>
                    <CardMedia
                        sx={{
                            height: 64,
                            width:  64,
                            borderRadius :2
                        }}
                        image={"https://nav.bcdesign.cn/uploads/"+replaceChar(props.logo_pic,'\\\\','/')}
                        title="green iguana"
                    />
                </Box>

                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" sx={{fontSize:'16px',fontWeight:'bold'}}>
                        {props.title}
                    </Typography>
                    <BootstrapTooltip title={props.description}>
                        <Typography variant="body2" color="text.secondary" sx={{
                            fontSize:'12px',
                            color:'#8f8f8f',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            boxSizing: 'border-box',
                            height:52
                        }}>
                            {props.description.substr(0,24)+'...'}
                            {/*{props.description}*/}
                        </Typography>
                    </BootstrapTooltip>
                </CardContent>
                <CardActions sx={{
                    flexWrap:'wrap',
                    justifyContent:'center'
                }}>
                    {keywordHTML}
                </CardActions>
            </Card>

        </Box>

    );
}
