import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Drawer from '@mui/material/Drawer';
import NavLeft from "../NavLeft/NavLeft";
import {useState} from "react";
import {useMyContext} from "../../store/stateContext";
import {useMediaQuery} from "@mui/material";
import theme from "../../store/theme";


export default function Header(){

    let isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    const store = useMyContext();
    const toggleDrawer = (newOpen) => () => {
        store.setShowNav(newOpen);
    };

    return <Box sx={{
        height:'64px',
        zIndex:10,
        position:'fixed',
        left:0,
        top:0,
        right:0,
        backgroundColor:'secondary.main',
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
    }}>

            <Button
                sx={{

                    background: 'rgba(0, 0, 0,0)',
                    webkitBackdropFilter: ' blur(10px)',
                    backdropFilter: 'blur(10px)',
                    '&:hover': {
                        background: 'rgba(0, 0, 0,0.1)', // 鼠标移入时的背景颜色
                    },
                }}
                onClick={()=>{
                    store.setShowNav(isDownSm);
                }}
            >
                <Typography color="#ffffff" variant="button">3D打印信息导航</Typography>
            </Button>

        <Drawer open={store.isShowNav} anchor={'top'} onClose={toggleDrawer(false)}>
            <NavLeft></NavLeft>
        </Drawer>




    </Box>;
}
