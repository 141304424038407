import Box from "@mui/material/Box";
import "./Footer.css";

export default function Footer(){
    return <Box
        sx={{
            textAlign:'center',
            fontSize:'12px',
            color:'rgba(255, 255, 255, 0.7)',
            backgroundColor:'#000000',
            lineHeight:'2.08333333em',
            padding:'22px 24px',
            position:'relative',
            zIndex:10
        }}
        className={"footer"}
    >
        <Box className={"statement"}>
            <span> 测试测试
                <i>，特此声明。</i>
            </span>
        </Box>

        <Box className={"links"}>
            <a href="https://www.bcdesign.cn" target={"_blank"}>广告合作</a>
            <a href="https://www.bcdesign.cn" target={"_blank"}>联系我们</a>
            <a href="https://www.bcdesign.cn" target={"_blank"}>3d打印</a>
        </Box>

        <Box className={"copyright"}>
            Copyright © 2024 <a href="#" target={"_blank"}>ISDC</a>
            <a href="https://beian.miit.gov.cn/" target={"_blank"}>苏ICP备16005435号-1</a>
        </Box>

    </Box>;
}
