import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { MyContextProvider} from './store/stateContext';
import App from './App';
import {BrowserRouter as Router} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
     <MyContextProvider>
         <Router>
             <App />
         </Router>
     </MyContextProvider>
  </React.StrictMode>
);
