import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import {useState} from "react";
import CardItem from "../Main/CardItem/CardItem";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function FormDialog() {
    const [open, setOpen] = useState(false);

    //当用户提交链接信息成功后的提示信息
    const [openAlert,setOpenAlert] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //提示信息打开
    const handleClickAlert = ()=>{
        setOpenAlert(true);
    }
    //提示信息关闭
    const handleCloseAlert = () => {
        setOpenAlert(false);
    }


    return (
        <>
            <Button sx={{
                backgroundColor:'#191919',
                '&:hover': {
                    backgroundColor:'#191919',
                },
            }} variant="outlined" onClick={handleClickOpen}>
                提交收录
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());

                        //连接后端代码,插入数据
                        axios.post("http://192.168.1.16/index.php/addLink",{

                            formJson

                        },{
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(function (response) {

                            console.log(response.data);

                            handleClickAlert(true);

                        }).catch(function (error) {

                            console.log(error);
                        })

                        console.log(formJson);

                        handleClose();
                    },
                }}
            >
                <DialogTitle>添加自定义网址</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="webTitle"
                        name="webtitle"
                        label="网站标题"
                        type="text"
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="keywords"
                        name="keywords"
                        label="关键词(以空格分隔)"
                        type="text"
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        id="outlined-multiline-static"
                        label="简介"
                        multiline
                        rows={4}
                        fullWidth
                        required
                        name={"webdesc"}
                        defaultValue="简介"
                        sx={{
                            marginTop:'10px',
                            marginBottom:'10px'
                        }}
                    />
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="urlName"
                        name="url_address"
                        label="网址"
                        type="url"
                        fullWidth
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={{
                        backgroundColor:'#191919',
                        '&:hover': {
                            backgroundColor:'#191919',
                        },
                    }}>取消</Button>
                    <Button type="submit"
                        sx={{
                            backgroundColor:'#191919',
                            '&:hover': {
                                backgroundColor:'#191919',
                            },
                        }}
                    >添加</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={openAlert}
                autoHideDuration={3000}
                onClose={handleCloseAlert}
                message="感谢参与！"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
            </Snackbar>
        </>
    );
}
