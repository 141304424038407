import NavLeft from "./components/NavLeft/NavLeft";
import "./App.css";
import Box from "@mui/material/Box";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Main from "./components/Main/Main";
import {createTheme,ThemeProvider,useTheme} from "@mui/material/styles";
import Checkbox from '@mui/material/Checkbox';
import { green, orange } from '@mui/material/colors';
import {useMediaQuery} from "@mui/material";
import axios from "axios";
import theme from './store/theme';
import FormDialog from "./components/FormDialog/FormDialog";
import Favorite from "./components/Favorite/Favorite";

import { useRoutes } from "react-router-dom";
import routes from "./routes";


function App() {

    const element = useRoutes(routes);


      return (

          <ThemeProvider theme={theme}>

                <Box className="App" sx={{
                    width:'100vw',
                    backgroundColor:'primary.main',
                    paddingTop:'70px',
                    overflowX: 'hidden'
                }}>

                    <Header></Header>

                    {element}

                    <Favorite></Favorite>

                    <Footer></Footer>


                </Box>

          </ThemeProvider>

      );
}

export default App;


