import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import axios from "axios";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';

import {useState,useEffect} from "react";
import {useMyContext} from "../../store/stateContext";

import Collapse from '@mui/material/Collapse';
import {NavLink} from "react-router-dom";



function NavLeft(){
    const [linkId,setLinkId] = useState(1);

    let store = useMyContext();

    let navData;

    let [dataLis,setDataLis]=useState('');
    let [currentAnchor,setCurAnchor] = useState('lingbujian');

    const [selectedIndex, setSelectedIndex] = useState('lingbujian');

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        store.setShowNav(false);
    };


    useEffect(() => {

         axios.post("https://nav.bcdesign.cn/index.php/getCategory",{},{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(function (response) {

            navData = response.data;

            let initAnchor= navData[0].anchor;

            setCurAnchor(initAnchor);

            // let res = navData.map(item=>(<li key={item.id} className={item.anchor == currentAnchor ? 'menu-item active' :'menu-item'}><a href={'#'+item.anchor} className={'menu-a'}>{item.title}</a></li>));

            // setDataLis(res);

             // navData.unshift({
             //     anchor:'hot',
             //     title:'热门推荐',
             //     id:55,
             //     pid:1
             // })
             console.log(navData)

            let res = navData.map(item=>{

                // return (
                //     <ListItemButton
                //         key={item.id}
                //         selected={selectedIndex === item.anchor}
                //         onClick={(event) => {
                //             handleListItemClick(event, item.anchor);
                //             smoothJumpToAnchor(item.anchor);
                //         }}
                //         sx={{
                //             marginLeft:'0',
                //             textAlign:'center',
                //             borderRadius:'10px',
                //             minWidth:'170px',
                //             boxSizing:'border-box',
                //
                //             '&.Mui-selected': {
                //                 backgroundColor: 'white', // 点击选中时的背景颜色
                //                 '&:hover': {
                //                     backgroundColor: 'white', // 点击选中且鼠标移上时的背景颜色
                //                 },
                //                 color:'#161616',
                //                 border:'2px solid #0f62fe',
                //                 '& .MuiTypography-root': {
                //                     fontWeight: 'bold' // 鼠标移上时文字变为粗体
                //                 },
                //             },
                //             '&:hover': {
                //                 color:'#161616',
                //                 backgroundColor: 'white', // 鼠标移上时的背景颜色
                //                 '& .MuiTypography-root': {
                //                     fontWeight: 'bold' // 鼠标移上时文字变为粗体
                //                 }
                //             }
                //         }}
                //     >
                //         <ListItemText primary={
                //             <Typography variant="body1" noWrap sx={{ transition: 'font-weight 0.3s' }}>
                //                 {item.title}
                //             </Typography>
                //         } sx={{
                //             color:'#525252',
                //             paddingLeft: '0px', // 左边距
                //             paddingRight: '0px', // 右边距
                //             boxSizing:'content-box'
                //         }} />
                //     </ListItemButton>
                // );



                return (
                    <>
                        <NavLink to={"/type/"+item.id}>

                            <ListItemButton onClick={
                                (event)=> {
                                    handleListItemClick(event, item.anchor);
                                }
                            }>
                                    <ListItemText primary={item.title} />
                            </ListItemButton>
                        </NavLink>

                        <Collapse in={selectedIndex === item.anchor} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>

                                {
                                    item.children.map(child=>{
                                        return (
                                            <ListItemButton sx={{ pl: 6 }} onClick={()=>{
                                                smoothJumpToAnchor(child.anchor);
                                            }}>
                                                <ListItemText primary={child.title} />
                                            </ListItemButton>
                                        );
                                    })
                                }

                            </List>
                        </Collapse>
                    </>
                );


            })

             setDataLis(res);

        }).catch(function (error) {

            console.log(error);
        })


    }, [selectedIndex]);

    useEffect(() => {
        // console.log('search changed',store.isSearch);
        if(store.isSearch === false){
            if(document.getElementById(currentAnchor) !== null ){
                document.getElementById(currentAnchor).scrollIntoView({
                    behavior: 'smooth'
                });
            }

        }
    }, [store.isSearch]);

    function scrollToAnchorWithOffset(anchorId, offset) {
        const element = document.getElementById(anchorId);
        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    }


    function smoothJumpToAnchor(anchor) {
        // console.log(anchor);
        if(document.getElementById(anchor) === null){
            store.setSearch(false);

            setCurAnchor(anchor);
        }else{

            scrollToAnchorWithOffset(anchor,80);
        }
    }


    // const [open, setOpen] = useState(true);
    // const [open2, setOpen2] = useState(false);
    //
    // const handleClick = () => {
    //     setOpen(!open);
    //     setOpen2(false);
    // };
    //
    // const handleClick2 = () => {
    //     setOpen2(!open2);
    //     setOpen(false);
    // };



    return <>

            <List sx={{width:'100%',height:'50vh',minWidth:'170px'}} component="nav" aria-label="secondary mailbox folder">
                {dataLis}

                {/*<ListItemButton onClick={handleClick2}>*/}
                {/*    <ListItemText primary="Drafts" />*/}
                {/*</ListItemButton>*/}

                {/*<Collapse in={open2} timeout="auto" unmountOnExit>*/}
                {/*    <List component="div" disablePadding>*/}
                {/*        <ListItemButton sx={{ pl: 6 }}>*/}
                {/*            <ListItemText primary="Starred" />*/}
                {/*        </ListItemButton>*/}

                {/*        <ListItemButton sx={{ pl: 6 }}>*/}
                {/*            <ListItemText primary="Starred123" />*/}
                {/*        </ListItemButton>*/}
                {/*    </List>*/}
                {/*</Collapse>*/}

                {/*<ListItemButton onClick={handleClick}>*/}

                {/*    <ListItemText primary="Inbox" />*/}

                {/*</ListItemButton>*/}
                {/*<Collapse in={open} timeout="auto" unmountOnExit>*/}
                {/*    <List component="div" disablePadding>*/}
                {/*        <ListItemButton sx={{ pl: 6 }}>*/}
                {/*            <ListItemText primary="Starred" />*/}
                {/*        </ListItemButton>*/}

                {/*        <ListItemButton sx={{ pl: 6 }}>*/}
                {/*            <ListItemText primary="Starred123" />*/}
                {/*        </ListItemButton>*/}
                {/*    </List>*/}
                {/*</Collapse>*/}
            </List>

    </>

}

export default NavLeft;
