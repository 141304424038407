
import {Navigate} from "react-router-dom";
import Main from "../components/Main/Main";

export default [
    {
        path:'/type/:id',
        element:<Main />
    },
    {
        path:'/',
        element:<Navigate to={"/type/39"} />
    }
]
