import React, { useRef,createContext, useState, useContext } from 'react';
import { useMediaQuery } from '@mui/material'

// 创建上下文对象（使用 PascalCase 命名约定）
const MyContext = createContext();

// 上下文 Provider 组件
export const MyContextProvider = ({ children }) => {

    //头部导航显示
    const [isShowNav,setShowNav] = useState(false);
    //搜索界面是否正在显示
    const [isSearch,setSearch] = useState(false);


    const contextValues = {
        isShowNav,
        isSearch,
        setShowNav,
        setSearch
    };


    return (
        <MyContext.Provider value={contextValues}>
            {children}
        </MyContext.Provider>
    );
};

// 自定义 hook，用于访问 Context 中的状态
export const useMyContext = () => useContext(MyContext);
